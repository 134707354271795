<style lang="scss">
$width1200: 1200px;
.navi-sitebar {
  position: relative;
  @media (max-width: $width1200) {
    position: fixed;
    left: -400px;
    top: 0;
    height: 100vh;
    z-index: 100;
    background: #fff;
    max-width: 350px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: all 400ms ease-in-out;

    &.show {
      left: 0px;
    }
    @media (max-width: 500px) {
      left: -120%;
      max-width: 100%;
      width: 100% !important;
      z-index: 10000;
    }
    @media (max-width: 340px) {
      max-width: 100%;
      width: 100% !important;
    }
  }
  #navigation {
    width: 100%;
    z-index: 100;
    background: #fff;
    position: relative;
    .close-navi-sitebar {
      display: none;
      @media (max-width: $width1200) {
        .material-icons {
          margin-right: 0 !important;
        }
        text-align: center;
        display: block;
        //background: rgba(255, 254, 254, 0.85);
        //background: $blue-darker;
        text-align: center;
        padding: 10px 10px;
        cursor: pointer;
        position: absolute;
        right: 12px;
        top: 25px;
        z-index: 100;
        transition: all 400ms ease-in-out;

        @media (max-width: 340px) {
          padding: 0;
          right: 0px;
          position: relative;
          background: transparent;
          text-align: right;
        }
        i {
          line-height: 1px;
          height: 1px;
          position: relative;
          top: 8px;
          left: 0px;
          transition: all 400ms ease-in-out;
          font-size: 190%;
          color: #333;
          @media (max-width: 340px) {
            left: 0px;
            color: #fff;
            line-height: 40px;
            width: 40px;
            height: 40px;
            margin-bottom: 10px;
            text-align: center;
            background: $blue-darker;
            top: 0px;
          }
        }
        &:hover {
          background: #eee;
          i {
            transform: rotate(180deg);
            color: $font-color;
          }
        }
      }
    }
    .header {
      display: block;
      background: #fff;
      text-align: left;
      color: #999;
      letter-spacing: 1px;
      padding: 20px 15px 10px 15px;
      //border-bottom: 1px solid #eee;
      position: relative;

      a {
        display: block;
        position: relative;
        z-index: 5;
      }
      .router-link-active {
        border: 0;
        &:after {
          display: none !important;
        }
      }
      img {
        max-width: 110px;
      }
      @media (max-width: 400px) {
        font-size: 90%;
      }
    }
    .claim {
      padding: 10px 15px 20px 15px;
      border-bottom: 1px solid #eee;
      h1 {
        font-size: 180%;
      }
    }

    .navigation-main {
      padding-bottom: 50px;
      @media (max-width: $width1200) {
        padding-bottom: 50px;
      }

      .divider {
        //background: #f1f1f1;
        padding: 17px 5px;
        color: #666;
        font-size: 95%;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: all 300ms ease-in-out;
        @media (max-width: 992px) {
          font-size: 80%;
        }
        @media (max-width: 400px) {
          font-size: 90%;
        }
        &:hover {
          background: #ddd;
        }
      }
      .extern-link {
        text-align: left;
        display: block;
        padding: 15px 15px;
        border-bottom: 1px solid #eee;
        &:hover {
          color: #888;
          background: $blue-darker;
          color: #fff;
          padding-left: 25px;
          @media (max-width: 768px) {
            padding-left: 20px;
          }
        }
      }
      .extern-link-v2 {
        text-align: left;
        display: block;
        padding: 15px 15px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        &:hover {
          color: #888;
          background: $blue-darker;
          color: #fff;
          padding-left: 25px;
          @media (max-width: 768px) {
            padding-left: 20px;
          }
        }
      }
      .extern-link-first-level {
        text-align: left;
        display: block;
        padding: 15px 15px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        &:hover {
          color: #888;
          background: $blue-darker;
          color: #fff;
          padding-left: 25px;
          @media (max-width: 768px) {
            padding-left: 20px;
          }
        }
      }
    }

    @media (max-width: $width1200) {
      height: 100vh;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #ccc #eee;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        background: #eee;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 20px;
        border: 3px solid #eee;
      }
    }
  }
  .material-icons {
    line-height: 1px;
    height: 1px;
    position: relative;
    font-size: 140%;
    top: 5px;
    left: 0px;
    margin-right: 8px;
  }
}
</style>
<template>
  <div class="navi-sitebar" :class="{ show: menu }">
    <nav id="navigation">
      <div class="close-navi-sitebar" @click="toggleMenu"><i class="material-icons">close</i></div>
      <div class="header">
        <router-link to="/"><img src="@/assets/img/generel/klz-logo.svg" alt="Kleine Zeitung"/></router-link>
      </div>
      <div class="claim">
        <h1>Wir verbinden Marken mit Menschen</h1>
      </div>
      <!-- <div class="header" v-if="showLogo">KLEINE ZEITUNG // TARIF</div> -->
      <div class="navigation-main">
        <!-- <NavAccordion :link="item" v-for="item in items" :key="item.name"></NavAccordion> -->
        <!--  <NavLink :to="{ name: item.name }" :key="item.name" v-for="item in items">--{{ item.name }}--</NavLink> -->
        <NavLink to="/">Start</NavLink>
        <NavAccordion titleclass="divider" title="Digital">
          <NavLink :to="{ name: 'DisplayWerbung' }">Displaywerbung</NavLink>
          <NavLink :to="{ name: 'Native' }">Content / Native</NavLink>
          <NavLink :to="{ name: 'EPaper' }">E-Paper-Werbung</NavLink>
          <a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/auktion/" target="_blank" class="extern-link-first-level">Online-Auktion</a>
          <!--<NavLink :to="{ name: 'Digitales Prospekt' }">Digitales Prospekt</NavLink>
          <NavLink :to="{ name: 'Specials' }">Specials</NavLink>-->
        </NavAccordion>

        <NavAccordion titleclass="divider" title="Print">
          <NavLink :to="{ name: 'Platzierungen' }">Platzierungen</NavLink>
          <NavLink :to="{ name: 'Regional' }">Regional</NavLink>
          <NavLink :to="{ name: 'Exklusivplatzierung' }">Exklusivplatzierung</NavLink>
          <NavLink :to="{ name: 'TopplatzierungPolitik' }">Top-Platzierung Politik</NavLink>
          <NavLink :to="{ name: 'Themenplatzierung' }">Themenplatzierung</NavLink>
          <NavLink :to="{ name: 'Basisplatzierung' }">Basisplatzierung</NavLink>
          <NavLink :to="{ name: 'NativeContent' }">Content</NavLink>
        </NavAccordion>
        <NavAccordion titleclass="divider" title="Beilagen &amp; Sonderwerbeformen">
          <NavLink :to="{ name: 'Beilagen' }">Beilagen</NavLink>
          <NavLink :to="{ name: 'Mitdruck' }">Mitdruck</NavLink>
          <NavLink :to="{ name: 'Mantel' }">Mantel</NavLink>
          <NavLink :to="{ name: 'Tiponcard' }">Tip-on-Card</NavLink>
        </NavAccordion>
        <NavAccordion titleclass="divider" title="Branchenlösungen &amp; Sonderthemen">
          <NavLink :to="{ name: 'Themenschwerpunkte' }">Themenschwerpunkte</NavLink>
          <NavLink :to="{ name: 'BildungBeruf' }">Beruf &amp; Bildung</NavLink>
          <NavLink :to="{ name: 'Wohnen' }">Wohnen</NavLink>
          <NavLink :to="{ name: 'Mobilitaet' }">Mobilität</NavLink>
          <NavLink :to="{ name: 'Reise' }">Reise</NavLink>
          <!--<NavLink :to="{ name: 'VeranstaltungenKulinarik' }">Veranstaltungen &amp; Kulinarik</NavLink>-->
          <!--<a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/reise/" target="_blank" class="extern-link">reise.kleinezeitung.at</a>-->
        </NavAccordion>
        <NavAccordion titleclass="divider" title="Junge Zielgruppe">
          <NavLink :to="{ name: 'Kinderzeitung' }">Kleine Kinderzeitung</NavLink>
          <NavLink :to="{ name: 'Pausenzeitung' }">Pausenzeitung</NavLink>
          <NavLink :to="{ name: 'Futter' }">Futter</NavLink>

          <!--<a href="https://trauer.kleinezeitung.at/traueranzeigen/anzeigeaufgeben1" target="_blank" class="extern-link">Trauertarif allgemein</a>-->
        </NavAccordion>

        <NavAccordion titleclass="divider" title="Service">
          <NavLink :to="{ name: 'Allgemeine Informationen' }">Allgemeine Informationen</NavLink>
          <NavLink :to="{ name: 'Technische Daten' }">Technische Daten</NavLink>
          <NavLink :to="{ name: 'Mediadaten' }">Mediadaten</NavLink>
          <!--<a href="../../pdf/allgemeines/KLZ-Fact-Sheet_Tarif_2021-v2.pdf" target="_blank" class="extern-link">Mediadaten</a>-->
          <NavLink :to="{ name: 'Spezifikationen' }">Spezifikationen</NavLink>
          <NavLink :to="{ name: 'calculator' }">Druckkosten- und Beilagenrechner </NavLink>
          <NavLink :to="{ name: 'zielgruppenrechner' }">Zielgruppenkalkulator </NavLink>
          <a href="https://selfservice.kleinezeitung.at/webstore/" target="_blank" class="extern-link">Anzeigen aufgeben</a>
        </NavAccordion>
        <NavAccordion titleclass="divider" title="Kontakt">
          <NavLink :to="{ name: 'UnserTeam' }">Unser Team</NavLink>
          <NavLink :to="{ name: 'KontaktFormular' }">Anfrage</NavLink>
        </NavAccordion>
        <a href="../../pdf/allgemeines/Gesamt-PDF-Tarif-2022-WEB.pdf" target="_blank" class="extern-link-v2">Gesamttarif 2022</a>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from 'vue';
import NavLink from '@/components/navigation/NavLink.vue';
import { useStore } from 'vuex';
import NavAccordion from '@/components/navigation/NavAccordion.vue';

export default defineComponent({
  components: {
    NavLink,
    NavAccordion,
  },
  setup() {
    const store = useStore();
    const width = computed(() => store.state.windowsize);
    const menu = computed(() => store.state.menustate);
    const showLogo = computed(() => width.value > 1400);

    const items = computed(() => {
      return store.state.menu.items.hierarchical;
    });

    const toggleMenu = () => {
      store.state.menustate = !store.state.menustate;
    };

    watchEffect(() => {
      if (width.value > 1400) {
        store.state.menustate = true;
      } else {
        store.state.menustate = false;
      }
    });

    return { items, menu, toggleMenu, showLogo };
  },
});
</script>
