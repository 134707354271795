<style lang="scss">
footer {
  //padding-top: 50px;
  padding-bottom: 0px;
  margin-left: 350px;
  position: relative;

  /*&:before {
    content:"";
    display:block;
    width:100%;
    height:1px;
    position:absolute;
    top:-5px;
    background:#eee;

  }*/
  @media (max-width: 1200px) {
    margin-left: 0;
  }
  [class*='col-'] {
    border: 0px solid blue;
  }
  .highlight-box {
    position: relative;
    top: 75px;
    //box-shadow: rgba(27,0,84, 0.05) 0px 4px 16px, rgba(27,0,84, 0.05) 0px 8px 32px;
    //box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 25px 20px -20px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      width: 50%;
      height: 50%;
      left: 10%;
      bottom: 2px;
      box-shadow: rgba(0, 0, 0, 0.15) 5px 25px 20px -20px;
    }
  }
  .bg-footer-inner {
    background: #f9f9f9;
    padding-top: 70px;
  }
  .info-txt {
    font-family: $KleineTitel-Bold;
    font-size: 160%;
    padding: 20px 0;
    @media (max-width: $width-sm) {
      text-align: center;
      display: block;
      width: 100%;
    }
  }

  .sub-navi {
    //border:1px solid red;
    margin-top: 50px;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
      text-align: center;
    }
    @media (max-width: $width-sm) {
      margin-top: 40px;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      text-align: center;
      display: block;
      width: 100%;
      li {
        margin: 5px 15px;
        display: inline-block;
        @media (max-width: $width-sm) {
          display: block;
        }
        a,
        .modal-button {
          display: block;
          font-size: 90%;
          color: $font-color;
          &:hover {
            color: $blue !important;
          }
        }
        .router-link-active {
          border-left: 0;
          color: $blue !important;
          &:after {
            display: none !important;
          }
        }
      }
    }
  }
  .info-box-mail {
    text-align: center;
    @media (max-width: 1200px) {
      margin-top: 40px;
    }
    .txt {
      display: block;
      font-size: 140%;
      margin-bottom: 20px;
      font-family: $KleineTitel-Bold;
    }
    a {
      display: block;
      position: relative;
      max-width: 90%;
      margin: 0 auto;
      transition: all 200ms ease;

      .frontBg {
        display: inline-block;
        padding: 15px 20px 17px 20px;
        background: $blue-darker;
        color: #fff;
        position: relative;
        z-index: 3;
        transition: all 200ms ease;
      }
      .backBg {
        display: none;
        width: 100%;
        height: 100%;
        background: $blue;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 1;
      }
    }
    &:hover {
      .frontBg {
        opacity: 0.7;
      }
      .backBg {
        background: $blue-darker;
      }
    }
  }

  .line-bottom {
    position: relative;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    max-width: 90%;
    margin: 0px auto 0 auto;
    &:before {
      content: '';
      display: none;
      width: 98%;
      height: 1px;
      background: #fff;
      margin: 5px auto;
    }
    &:after {
      content: '';
      display: block;
      width: 98%;
      height: 1px;
      background: #ccc;
      margin: 5px auto;
    }
  }

  .socket {
    padding-bottom: 30px;

    span {
      display: block;
      font-size: 12px;
      text-align: center;
    }
  }
}

.smd-modal-container {
  .headline-modal {
    font-size: 150%;
    margin-bottom: 20px;
  }
  .impressum-content {
    h2 {
      padding-top: 0 !important;
    }
    a {
      color: $blue;
      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }
  }
}
</style>
<template>
  <footer>
    <div class="container">
      <div class="row justify-content-center align-self-center bg-blue-light highlight-box ">
        <div class="col-sm-5 d-flex align-self-center">
          <div class="info-txt">Sie wollen mehr wissen?</div>
        </div>
        <div class="col-sm-6 d-flex align-self-center justify-content-end">
          <div class="btn-std btn-bg-blue-dark">
            <router-link :to="{ name: 'KontaktFormular' }">Wir freuen uns auf Ihre Anfrage</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-footer-inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="sub-navi">
              <ul>
                <li><router-link :to="{ name: 'Mediadaten' }">Mediadaten</router-link></li>
                <li><a href="../../pdf/allgemeines/Gesamt-PDF-Tarif-2022-WEB.pdf" target="_blank">Gesamttarif 2022</a></li>
                <!--<li><a href="https://www.kleinezeitung.at/service/unternehmen/impressum/index.do" target="_blank">AGBs</a></li>-->
                <li><router-link :to="{ name: 'AGB' }">AGBs</router-link></li>

                <li><a href="https://www.kleinezeitung.at/service/unternehmen/impressum/5104679/Datenschutz_Datenschutzinformation-Werbemarkt" target="_blank">Datenschutzinformationen</a></li>
                <li><a href="javascript:window.__tcfapi('showConsentManager')" class="list__link">Cookie Einstellungen ändern</a></li>
                <li><ModalButton link="impressum">Impressum</ModalButton></li>

                <!-- </ul>
              <li><router-link :to="{ name: 'calculator' }">Tarif Rechner</router-link></li>
              <li><a href="https://klz-verkauf.at/wp-content/uploads/vu-daten/Zielgruppenrechner/index.html" target="_blank">Zielgruppen-Rechner</a></li>
              <li><ModalButton link="quellen">Quellen</ModalButton></li>
              <li><ModalButton link="kontakt">Kontakt</ModalButton></li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="line-bottom"></div>
      <div class="socket">
        <span>© 2021, kleinezeitung.at/tarif | Kleine Zeitung GmbH &amp; Co KG</span>
      </div>
      <Modal link="quellen" title="Quellen">
        sadfasd
      </Modal>
      <Modal link="impressum" title="Impressum">
        <div class="impressum-content">
          <div class="headline-modal">Kleine Zeitung GmbH &amp; Co KG</div>
          <p>
            Informationen der<br />
            <a href="https://www.kleinezeitung.at/service/unternehmen/impressum/5023137/Unternehmen_Impressum-Kontaktadressen" target="_blank">Impressum gem. § 5 ECG, § 14 UGB, § 24 Mediengesetz</a>
          </p>
          <p>
            Impressum gem. § 5 ECG, § 14 UGB, § 24 Mediengesetz<br />
            <a href="https://www.kleinezeitung.at/offenlegungkleinezeitung" target="_blank">Offenlegung gemäß § 25 Mediengesetz</a>
          </p>
          <p>Gadollaplatz 1, 8010 Graz</p>
        </div>
      </Modal>
      <Modal link="kontakt" title="Kontakt">
        sadfasd
      </Modal>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Footer',
  components: {
    ModalButton,
    Modal,
  },
});
</script>
